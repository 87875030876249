import React from 'react';
import { Tooltip } from 'wix-ui-tpa/cssVars';

import { Chevron } from './Chevron';

import { st, classes } from './Accordion.st.css';
import { ALIGNMENT } from './constants';
import TextWithDirection from '../TextWithDirection';
import { FCWithChildren } from '@wix/challenges-web-library';
import { IAccordionProps } from './Accordion';
import { useTranslation } from '@wix/yoshi-flow-editor';

export interface INewAccordionProps extends IAccordionProps {
  isMobile?: boolean;
  toggleOpen: () => void;
}

export const NewAccordion: FCWithChildren<INewAccordionProps> = (props) => {
  const { t } = useTranslation();
  const {
    isMobile,
    opened,
    toggleOpen,
    id,
    prefixIcon,
    tooltipText,
    tooltipDisabled,
    title,
    subtitle,
    className,
    disabled,
    align = ALIGNMENT.left,
    children,
  } = props;

  return (
    <section
      className={st(
        classes.root,
        {
          state: opened ? 'opened' : 'closed',
          mobile: isMobile,
          disabled,
          align,
        },
        className,
        classes.newAccordion,
      )}
    >
      <h3
        style={{
          marginLeft: prefixIcon ? '12px' : 0,
        }}
        className={classes.buttonWrap}
      >
        <button
          type="button"
          aria-expanded={opened}
          aria-controls={id}
          id={`accordion-${id}`}
          className={classes.newTitleBar}
          onClick={toggleOpen}
          data-hook={props['data-hook']}
        >
          {prefixIcon ? (
            <Tooltip
              content={tooltipText}
              placement="top"
              appendTo="window"
              maxWidth={250}
              disabled={!!tooltipDisabled || !tooltipText}
            >
              <span className={classes.prefixIcon}>{prefixIcon}</span>
            </Tooltip>
          ) : null}
          <div className={classes.title}>
            <TextWithDirection>{title}</TextWithDirection>

            {subtitle ? <p className={classes.subtitle}>{subtitle}</p> : null}
          </div>
          <span
            className={classes.icon}
            aria-label={
              opened
                ? t('live.challenges-page.accordion-opened-label')
                : t('live.challenges-page.accordion-closed-label')
            }
            onClick={() => {
              props.onIconClick ? props.onIconClick() : toggleOpen();
            }}
          >
            {!props.disabled && <Chevron />}
          </span>
        </button>
      </h3>

      {opened && (
        <div
          id={id}
          role="region"
          aria-labelledby={`accordion-${id}`}
          aria-hidden={!opened}
          className={classes.content}
        >
          {children}
        </div>
      )}
    </section>
  );
};
