import React from 'react';
import { Tooltip } from 'wix-ui-tpa/cssVars';

import { Chevron } from './Chevron';

import { st, classes } from './Accordion.st.css';
import { ALIGNMENT } from './constants';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import TextWithDirection from '../TextWithDirection';
import { FCWithChildren } from '@wix/challenges-web-library';
import { NewAccordion } from './NewAccordion';

export interface IAccordionProps {
  title: React.ReactNode;
  disabled?: boolean;
  subtitle?: React.ReactNode;
  tooltipText?: string;
  tooltipDisabled?: boolean;
  prefixIcon?: React.ReactNode;
  'data-hook'?: string;
  opened?: boolean;
  forceOpened?: boolean;
  className?: string;
  align?: ALIGNMENT;
  id?: string;
  onClick?: Function;
  onIconClick?(): void;
  buttonRole?: 'tab' | 'button';
  isNew?: boolean;
}

export const Accordion: FCWithChildren<IAccordionProps> = (props) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const [opened, setOpened] = React.useState<boolean>(props.opened);

  React.useEffect(() => {
    setOpened(props.opened);
  }, [props.opened]);

  React.useEffect(() => {
    /*
      This is fckn awesome.
      We need an ability to open the accordion manually from outside.
      So the `opened` prop isn't working here, because the accordion can be closed by internal state,
       but the `opened` prop will still be `true`.
      And we need one more `super opened` prop to handle it, and it should works only for one direction (`false` to the `true`),
        and don't close the accordion in other direction.
     */
    if (props.forceOpened) {
      setOpened(true);
    }
  }, [props.forceOpened]);

  const toggleOpen = () => {
    if (props.disabled) {
      return;
    }
    setOpened(!opened);

    if (props.onClick) {
      props.onClick();
    }
  };
  if (props.isNew) {
    return (
      <NewAccordion
        {...props}
        isMobile={isMobile}
        toggleOpen={toggleOpen}
        opened={opened}
      />
    );
  }
  const {
    id,
    prefixIcon,
    tooltipText,
    tooltipDisabled,
    title,
    subtitle,
    className,
    disabled,
    align = ALIGNMENT.left,
    children,
    buttonRole = 'tab',
  } = props;

  return (
    <section
      className={st(
        classes.root,
        {
          state: opened ? 'opened' : 'closed',
          mobile: isMobile,
          disabled,
          align,
        },
        className,
      )}
    >
      <div className={classes.buttonWrap}>
        <button
          role={buttonRole}
          aria-expanded={opened}
          aria-controls={id}
          id={`accordion-${id}`}
          className={classes.titleBar}
          onClick={toggleOpen}
          data-hook={props['data-hook']}
        >
          {prefixIcon ? (
            <Tooltip
              content={tooltipText}
              placement="top"
              appendTo="window"
              maxWidth={250}
              disabled={!!tooltipDisabled || !tooltipText}
            >
              <span className={classes.prefixIcon}>{prefixIcon}</span>
            </Tooltip>
          ) : null}

          <h3
            className={classes.title}
            style={{
              marginLeft: prefixIcon ? '12px' : 0,
            }}
          >
            <TextWithDirection>{title}</TextWithDirection>
          </h3>

          {subtitle ? <p className={classes.subtitle}>{subtitle}</p> : null}
        </button>

        <button
          className={classes.icon}
          aria-label={
            opened
              ? t('live.challenges-page.accordion-opened-label')
              : t('live.challenges-page.accordion-closed-label')
          }
          onClick={() => {
            props.onIconClick ? props.onIconClick() : toggleOpen();
          }}
        >
          {!props.disabled && <Chevron />}
        </button>
      </div>

      {opened && (
        <div
          id={id}
          role="tabpanel"
          aria-labelledby={`accordion-${id}`}
          aria-hidden={!opened}
          className={classes.content}
        >
          {children}
        </div>
      )}
    </section>
  );
};
