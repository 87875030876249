import React from 'react';

import challengeSettings from '../../../settingsParams';
import groupBy from 'lodash/groupBy';
import { toParticipantSteps } from '../../../../../contexts/ParticipantStepsDataProvider/toParticipantSteps';
import format from 'date-fns/format';
import flatten from 'lodash/flatten';
import { getRightDateFromBackend } from '../../../../../selectors/dates';

import {
  IChallengeContext,
  useChallengeData,
} from '../../../../../contexts/storage-contexts/Challenge';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';

import { TextAlignment } from '../../../Settings/challengeSettings/challengeSettings.types';
import { ChallengeAgendaStepsList } from './components/ChallengeAgendaStepsList';

import { ChallengeAgendaSection } from './components/ChallengeAgendaSection';
import { classes, st } from './ChallengeAgenda.st.css';
import { DateFnsLocales } from '../../../../../services/DateFnsLocales';
import { isSelfPaced } from '../../../../../selectors/isSelfPaced';
import { FCWithChildren } from '@wix/challenges-web-library';
import type { Locale } from 'date-fns';
import type {
  Section,
  SectionWithSteps,
  Step,
} from '../../../../../types/v3Types';
import {
  getSectionSteps,
  getSectionTitle,
} from '../../../../../selectors/sections';

import { useV3 } from '../../../../../experiments/useV3';
import { getStartDate } from '../../../../../selectors/GetStartDate';

export interface IChallengeAgendaProps {
  className?: string;
  alignment?: TextAlignment;
}

const isLimitReached = (
  originalSteps: Step[],
  originalSections: SectionWithSteps[],
  limit,
) => {
  return (
    originalSteps?.length > limit ||
    flatten<SectionWithSteps['steps']>(originalSections?.map(getSectionSteps))
      .length > limit
  );
};

const getStepsWithLimit = (
  isOpened: boolean,
  originalSteps: Step[],
  limit: number,
) => {
  // eslint-disable-next-line no-nested-ternary
  return originalSteps
    ? isOpened
      ? originalSteps
      : originalSteps?.slice(0, limit)
    : null;
};

const getSectionsWithLimit = (
  isOpened: boolean,
  originalSections: SectionWithSteps[],
  limit: number,
  isFirstPageLimitReached: boolean,
): Section[] => {
  if (!originalSections) {
    return null;
  }
  if (isOpened || !isFirstPageLimitReached || !originalSections?.length) {
    return originalSections;
  }

  return originalSections?.reduce(
    (acc, section) => {
      const { sections, stepCount } = acc;
      if (stepCount < limit) {
        return {
          stepCount: Math.min(stepCount + section?.steps?.length, limit),
          sections: [
            ...sections,
            {
              ...section,
              steps: section?.steps?.slice(0, limit - stepCount),
            },
          ],
        };
      }
      return acc;
    },
    { sections: [], stepCount: 0 },
  ).sections;
};

export const ChallengeAgenda: FCWithChildren<IChallengeAgendaProps> = ({
  className = null,
  alignment = TextAlignment.Left,
}: IChallengeAgendaProps) => {
  const { isMobile } = useEnvironment();
  const settings = useSettings();
  const [opened, setOpened] = React.useState<boolean>(false);
  const { language } = useEnvironment();
  const isV3 = useV3();
  const {
    challengeData: { challenge } = { challenge: {} },
    challengeSections,
    challengeSteps,
  } = useChallengeData();
  const isSPC = isSelfPaced(challenge);
  const isSections =
    isV3 || (isSPC && challenge?.stepsSummary?.sectionsNumber > 0);

  const firstPageStepsLimit = settings.get(challengeSettings.agendaStepsShown);
  const isFirstPageLimitReached = isLimitReached(
    challengeSteps,
    challengeSections,
    firstPageStepsLimit,
  );
  const challengeStepsWithLimit: IChallengeContext['challengeSteps'] =
    getStepsWithLimit(opened, challengeSteps, firstPageStepsLimit);
  const challengeSectionsWithLimit = getSectionsWithLimit(
    opened,
    challengeSections,
    firstPageStepsLimit,
    isFirstPageLimitReached,
  );

  const startDate = getStartDate(challenge);
  const stepsByStartDate = (locales) =>
    !isSPC && challengeStepsWithLimit?.length
      ? groupBy(
          toParticipantSteps({
            ownerSteps: { steps: challengeStepsWithLimit || [] },
            initialDate: startDate,
            locale: locales[language],
          }),
          (step) => {
            return step?.dateFrame?.start;
          },
        )
      : null;
  return (
    <DateFnsLocales>
      {(locales: Record<string, Locale>) => {
        return (
          <div
            className={`${st(classes.root, {
              mobile: isMobile,
              alignment,
            })} ${className}`}
          >
            {isSPC && !isSections ? (
              <ChallengeAgendaStepsList steps={challengeStepsWithLimit || []} />
            ) : null}
            {isSPC && isSections ? (
              <>
                {(challengeSectionsWithLimit || []).map((section, i) => (
                  <ChallengeAgendaSection
                    key={section.id}
                    title={getSectionTitle(section)}
                    steps={getSectionSteps(section)}
                    id={section.id}
                    type="Accordion"
                  />
                ))}
              </>
            ) : null}
            {!isSPC && stepsByStartDate(locales)
              ? Object.keys(stepsByStartDate(locales)).map((date, i) => {
                  return (
                    <ChallengeAgendaSection
                      key={i}
                      title={format(getRightDateFromBackend(date), 'EEEE, PP', {
                        locale: locales[language],
                      })}
                      steps={stepsByStartDate(locales)[date]?.map(
                        (participantStep) =>
                          challengeSteps.find(
                            ({ id }) => id === participantStep.id,
                          ),
                      )}
                      type="Calendar"
                    />
                  );
                })
              : null}
            {isFirstPageLimitReached && !opened ? (
              <button
                className={classes.link}
                onClick={() => {
                  setOpened(true);
                }}
              >
                {settings.get(challengeSettings.agendaShowMoreText)}
              </button>
            ) : null}
          </div>
        );
      }}
    </DateFnsLocales>
  );
};

ChallengeAgenda.displayName = 'ChallengeAgenda';
ChallengeAgenda.defaultProps = {
  className: '',
};
